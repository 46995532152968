// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../assets/Loading.less'

// Props of the Component
const propTypes = {
    className: PropTypes.string,
};
const defaultProps = {
    className: '',
};

function Loading({ className }) {

    return <div className={`loading large-loading ${className}`} />;
}

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;

// Packages
import React, { useContext } from 'react';
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import LoadingBlocker from './LoadingBlocker';
// Contexts
import { IsLoadingContext } from '../contexts/IsLoading';

function DisplayIsLoadingInputBlocker() {
    const isLoading = useContext(IsLoadingContext)[0];

    return (
        <ConditionalRender condition={Object.keys(isLoading.blockInput).some((element) => isLoading.blockInput[element] === true)}>
            <LoadingBlocker/>
        </ConditionalRender>
    );
}

export default DisplayIsLoadingInputBlocker;

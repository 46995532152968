// Packages
import React from 'react';
import PropTypes from 'prop-types';
// Stores
import IsLoadingStore from '../contexts/IsLoading';
import GlobalStoreContext from '../contexts/GlobalStore';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
};

function ContextStores({ children }) {
    return (
        <GlobalStoreContext>
            <IsLoadingStore> 
                {children}
            </IsLoadingStore>
        </GlobalStoreContext>
    );
}

ContextStores.propTypes = propTypes;

export default ContextStores;

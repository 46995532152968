// Helpers
import initialState from './initialState';

const GlobalReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_RESPONSE':
            return { ...state, responseObject: action.payload};
        case 'SET_TO_INITIAL':
            return initialState;
        default:
            return state;
    }
};

export default GlobalReducer;
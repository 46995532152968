import React, {useEffect, useState, useContext} from "react";

//STYLES
import "../assets/PiiSearch.less"
//UI
import ConditionalRender from "../components/ConditionalRender";
//CONTEXT
import { IsLoadingContext } from "../contexts/IsLoading";
import { GlobalStoreContext } from "../contexts/GlobalStore";
//HELPERS
import { securePiiEnvironment } from "../helpers/securePiiEnvironmentParse";

function PiiSearch({props, children}){

    const [crmId, setCrmId] = useState("")
    const [ssnInGlobal, setSsnInGlobal] = useState(false)
    const [envConfig, setEnvConfig] = useState({})
    const [tenantSource, setTenantSource] = useState("")

    const [isLoading, isLoadingDispatch] = useContext(IsLoadingContext)
    const [globalStore, globalStoreDispatch] = useContext(GlobalStoreContext)

    useEffect(() => {
        // NOTE - Set up the users environment on initial render
        if(window.location.href.includes("localhost:9000") || window.location.href.includes("dev-")){
            setEnvConfig(securePiiEnvironment("dev"))
        } else if (window.location.href.includes("testing-")) {
            setEnvConfig(securePiiEnvironment("test"))
        } else {
            setEnvConfig(securePiiEnvironment("prod"))
        }
    }, [])

    const handleSearchForPii = (event) => {
        event.preventDefault()
        isLoadingDispatch({type: "ADD_INPUT_BLOCKER", payload: "account id input"})
        fetch(`${envConfig.url}/?crm_account_id=${crmId}`, {
            method: "GET",
            headers: {
                'Authorization': 'optimum',
                'x-api-key': envConfig.key
            },
        }).then((res) => res.json())
        .then((data) => {
            // Save data in global store
            // console.log("OPTIMUM DATA:", data)
            if("ssn" in data["msg"]){
                globalStoreDispatch({type: "ADD_RESPONSE", payload: data["msg"]})
                isLoadingDispatch({type: "REMOVE_INPUT_BLOCKER", payload: "account id input"})
                setTenantSource("optimum")
                setSsnInGlobal(true)
            } else {
                //Search for entry in Frontier DB
                fetch(`${envConfig.url}/?crm_account_id=${crmId}`, {
                    method: "GET",
                    headers: {
                        'Authorization': 'frontier',
                        'x-api-key': envConfig.key
                    },
                }).then((res) => res.json())
                .then((data) => {
                    // console.log("FRONTIER DATA:", data)
                    if("ssn" in data["msg"]){
                        globalStoreDispatch({type: "ADD_RESPONSE", payload: data["msg"]})
                        setTenantSource("frontier")
                        setSsnInGlobal(true)
                    } else {
                        setSsnInGlobal(false)
                    }
                    isLoadingDispatch({type: "REMOVE_INPUT_BLOCKER", payload: "account id input"})
                })
            }
        })
    }

    const handleDeleteEntry = () => {
        const body = JSON.stringify({
            "crm_account_id": globalStore.responseObject.crm_account_id
        })
        isLoadingDispatch({type: "ADD_INPUT_BLOCKER", payload: "delete entry"})
        fetch(envConfig.url, {
            method: "DELETE",
            headers: {
                'Authorization': tenantSource,
                'x-api-key': envConfig.key
            },
            body
        })
        globalStoreDispatch({type: "SET_TO_INITIAL"})
        setSsnInGlobal(false)
        setCrmId("")
        setTenantSource("")
        isLoadingDispatch({type: "REMOVE_INPUT_BLOCKER", payload: "delete entry"})
    }

    return(
        <div className="PiiSearch-container">
            <div>
                <form>
                    <label htmlFor="account-id-input">Account Id:</label>
                    <input onChange={(event) => setCrmId(event.target.value)} value={crmId} type="text" id="account-id-input" ></input>
                    <button onClick={(event) => handleSearchForPii(event)} type="submit">Search</button>
                </form>
            </div>
            
            <ConditionalRender condition={ssnInGlobal}>
                <div>
                    <p>{globalStore.responseObject.ssn}</p>
                    <button onClick={handleDeleteEntry}>Delete Entry</button>
                </div>
            </ConditionalRender>

            {/* <ConditionalRender condition={!ssnInGlobal}>
                <div>
                    <p>No Item Found!</p>
                </div>
            </ConditionalRender> */}

            <div className="log-out-container">
                {children}
            </div>
        </div>
    );
}

export default PiiSearch;
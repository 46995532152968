export function securePiiEnvironment(env="prod") {
    switch(env){
        case 'dev':
            return {
                "url": "https://4pgmiu5ss8.execute-api.us-east-1.amazonaws.com/prod",
                "key": process.env.DEV_SECUREPII_X_API_KEY
            }
        case 'test':
            return {
                "url": "https://htnh2yaalh.execute-api.us-east-1.amazonaws.com/prod",
                "key": process.env.TEST_SECUREPII_X_API_KEY
            }
        default:
            return {
                "url": "https://f5o35yoipl.execute-api.us-east-1.amazonaws.com/prod",
                "key": process.env.PROD_SECUREPII_X_API_KEY
            }
    }
}

export function securePiiCognito(env="prod"){
    switch(env){
        case 'dev':
            return {
                "userPoolId": "us-east-1_sjnlnvOO7",
                "userPoolWebClientId": "s6e6lpdh4e5ds9ot47po99uuq"
            }
        case 'test':
            return {
                "userPoolId": "",
                "userPoolWebClientId": ""
            }
        default:
            return {
                "userPoolId": "",
                "userPoolWebClientId": ""
            }
    }
}
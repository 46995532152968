// Packages
import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
//UI
import DisplayIsLoadingInputBlocker from './ui/DisplayIsLoadingInputBlocker';
import DisplayIsLoadingPageBlocker from './ui/DisplayIsLoadingPageBlocker';
//COMPONENTS
import ContextStores from './components/ContextStores';
//PAGES
import PiiSearch from "./pages/PiiSearch"
// CSS
import 'normalize.css';
//HELPERS
import { securePiiCognito } from './helpers/securePiiEnvironmentParse';

//NOTE - Testing Amazon Cognito
if(window.location.href.includes("localhost")){
    // NOTE - Only Testing Cognito creds for now!
    const cognito = securePiiCognito("dev")
    Amplify.configure({
        Auth: {
            region: "us-east-1",
            userPoolId: cognito.userPoolId,
            userPoolWebClientId: cognito.userPoolWebClientId
        }
    })
} else {
    Amplify.configure({
        Auth: {
            region: "us-east-1",
            userPoolId: process.env.USER_POOL_ID,
            userPoolWebClientId: process.env.USER_POOL_CLIENT_ID
        }
    })
}

function App() {

    return(
        <Authenticator>
            {({ signOut, user }) => (
                <ContextStores>
                    <DisplayIsLoadingInputBlocker></DisplayIsLoadingInputBlocker>
                    <DisplayIsLoadingPageBlocker>
                        <PiiSearch>
                            <button onClick={signOut}>Sign out</button>
                        </PiiSearch>
                    </DisplayIsLoadingPageBlocker> 
                </ContextStores>

            )}
        </Authenticator>
    )
;}

export default App;

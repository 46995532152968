// Packages
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import LoadingBlocker from './LoadingBlocker';
// Contexts
import { IsLoadingContext } from '../contexts/IsLoading';

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
};

function DisplayIsLoadingPageBlocker({ children }) {
    const isLoading = useContext(IsLoadingContext)[0];

    return (
        <ConditionalRender falseReturn={<LoadingBlocker/>} condition={!Object.keys(isLoading.blockPage).some((element) => isLoading.blockPage[element] === true)}>
            {children}
        </ConditionalRender>
    );
}

DisplayIsLoadingPageBlocker.propTypes = propTypes;

export default DisplayIsLoadingPageBlocker;

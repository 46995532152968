// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Components
import ConditionalRender from '../components/ConditionalRender';
// UI
import Loading from './Loading';
import "../assets/LoadingBlocker.less"

// Props of the Component
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};
const defaultProps = {
    children: null,
};

function LoadingBlocker({ children }) {

    return (
        <>
            <div className='blocker' />
            <div className='loading-blocker'>
                <Loading />
                <ConditionalRender condition={children}>
                    {children}
                </ConditionalRender>
            </div>
        </>
    );
}

LoadingBlocker.propTypes = propTypes;
LoadingBlocker.defaultProps = defaultProps;

export default LoadingBlocker;

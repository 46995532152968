// Helpers
import initialState from './initialState';

const EnvironmentReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_PAGE_BLOCKER':
            return { ...state, blockPage: { ...state.blockPage, [action.payload]: true }};
        case 'REMOVE_PAGE_BLOCKER':
            let tempPageState = { ...state.blockPage }
            delete tempPageState[action.payload]; 

            return {...state, blockPage: tempPageState};
        case 'ADD_INPUT_BLOCKER':
            return { ...state, blockInput: { ...state.blockInput, [action.payload]: true }};
        case 'REMOVE_INPUT_BLOCKER':
            let tempInputState = { ...state.blockInput }
            delete tempInputState[action.payload]; 

            return {...state, blockInput: tempInputState};
        case 'CLEAR_LOADING_BLOCKERS':
            return initialState;
        default:
            return state;
    }
};

export default EnvironmentReducer;
